import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigateWithLegalEntityID } from '@/hooks'
import { Link } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { SyntheticEvent, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getRelatedTransactionDetailsPath } from './RelatedTransactionLink.utils'

export const RelatedTransactionLink: React.FC<{ value?: Uuid }> = ({ value }) => {
    const intl = useIntl()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const formattedMessage = useMemo<string>(() => {
        return intl.formatMessage({
            id: 'app.table.cell.transaction_id'
        })
    }, [intl])
    const path = useMemo<string>(() => getRelatedTransactionDetailsPath(value), [value])
    const onNavigate = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            navigateWithLegalEntityID(path)
        },
        [path, navigateWithLegalEntityID]
    )

    if (!path?.length || !value) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Link onClick={onNavigate} textDecoration="underline" title={formattedMessage}>
            {formattedMessage}
        </Link>
    )
}
