import { SettingsIcon } from '@/components/@icons'
import { MainNestedLayout, ParentPageLayout } from '@/layouts'
import {
    AuditTrailFilterByOption,
    AuditTrailPage,
    ConnectedAccountDetailsHeader,
    ConnectedAccountDetailsPage,
    ConnectedAccountInternalAccountsPage,
    ConnectedAccountsPage,
    CustomFieldCreatePage,
    CustomFieldDetailsHeader,
    CustomFieldDetailsPage,
    CustomFieldEditPage,
    CustomFieldsPage,
    PaymentRetryRuleDetailsHeader,
    PaymentRetryRuleDetailsPage,
    PaymentRetryRulesPage,
    ReconciliationRuleDetailsHeader,
    ReconciliationRuleDetailsPage,
    ReconciliationRulesPage,
    RoleDetailsPage,
    RolesPage,
    UserDetailsHeader,
    UserDetailsPage,
    UserEditPage,
    UserInvitePage,
    UsersPage
} from '@/pages'
import { getNestedRoutesWithTabLayout, NavigationRoute, NotFoundRoute } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { Navigate } from 'react-router-dom'
import { NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS } from '../NavigationRoutesProvider.const'

export function getSettingsNavigationRoutes(): NavigationRoute {
    const { UUID, EDIT, INVITE, NEW, SETTINGS } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    const userRoutes = [
        {
            title: 'app.settings.tabs.details.title',
            path: SETTINGS.DETAILS,
            element: <UserDetailsPage />
        },
        {
            title: 'app.settings.tabs.audit_trail.title',
            path: SETTINGS.AUDIT_TRAIL,
            element: <AuditTrailPage filterBy={AuditTrailFilterByOption.AUTHOR} />
        },
        {
            title: 'app.settings.tabs.history.title',
            path: SETTINGS.HISTORY,
            element: (
                <AuditTrailPage
                    objectType={ApiObjectTypeExtendedSchema.enum.user}
                    filterBy={AuditTrailFilterByOption.OBJECT}
                />
            )
        }
    ]

    const connectedAccountsRoutes = [
        {
            title: 'app.accounts.tabs.details.title',
            path: SETTINGS.DETAILS,
            element: <ConnectedAccountDetailsPage />
        },
        {
            title: 'app.accounts.tabs.internal_accounts.title',
            path: SETTINGS.INTERNAL_ACCOUNTS,
            element: <ConnectedAccountInternalAccountsPage />
        },
        {
            title: 'app.accounts.tabs.audit_trail.title',
            path: SETTINGS.AUDIT_TRAIL,
            element: (
                <AuditTrailPage
                    objectType={ApiObjectTypeExtendedSchema.enum.connected_account}
                    filterBy={AuditTrailFilterByOption.OBJECT}
                />
            )
        }
    ]

    const customFieldsRoutes = [
        {
            title: 'app.settings.tabs.details.title',
            path: SETTINGS.DETAILS,
            element: <CustomFieldDetailsPage />
        }
        /**
         * @todo
         * Enable in V1.3 of Custom Fields
         *         {
         *             title: 'app.settings.tabs.audit_trail.title',
         *             path: SETTINGS.AUDIT_TRAIL,
         *             element: (
         *                 <AuditTrailPage
         *                     objectType={ApiObjectTypeExtendedSchema.enum.custom_field}
         *                     filterBy={AuditTrailFilterByOption.OBJECT}
         *                 />
         *             )
         *         }
         */
    ]

    const reconciliationRulesRoutes = [
        {
            title: 'app.settings.tabs.details.title',
            path: SETTINGS.DETAILS,
            element: <ReconciliationRuleDetailsPage />
        }
    ]

    const paymentRetryRulesRoutes = [
        {
            title: 'app.settings.tabs.details.title',
            path: SETTINGS.DETAILS,
            element: <PaymentRetryRuleDetailsPage />
        }
    ]

    return {
        title: 'app.settings.title',
        path: 'settings',
        configuration: {
            isFooter: true
        },
        icon: <SettingsIcon />,
        element: <MainNestedLayout />,
        routes: [
            { index: true, element: <Navigate replace={true} to="connected-accounts" /> },
            {
                title: 'app.settings.connected_accounts.title',
                path: 'connected-accounts',
                element: <ParentPageLayout element={<ConnectedAccountsPage />} />,
                routes: [
                    ...getNestedRoutesWithTabLayout(UUID, connectedAccountsRoutes, {
                        header: <ConnectedAccountDetailsHeader />
                    })
                ]
            },
            {
                title: 'app.settings.users.title',
                path: 'users',
                element: <ParentPageLayout element={<UsersPage />} />,
                routes: [
                    {
                        title: 'app.settings.users.edit.title',
                        path: EDIT,
                        element: <UserEditPage />
                    },
                    {
                        title: 'app.settings.users.add.title',
                        path: INVITE,
                        element: <UserInvitePage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, userRoutes, {
                        header: <UserDetailsHeader />
                    })
                ]
            },
            {
                title: 'app.settings.roles.title',
                path: 'roles',
                element: <ParentPageLayout element={<RolesPage />} />,
                routes: [
                    {
                        title: 'app.settings.roles.tabs.details.title',
                        path: UUID,
                        element: <RoleDetailsPage />
                    },
                    NotFoundRoute
                ]
            },
            {
                title: 'app.settings.audit_trail.title',
                path: 'audit-trail',
                element: <ParentPageLayout element={<AuditTrailPage />} />
            },
            {
                title: 'app.settings.custom_fields.title',
                path: 'custom-fields',
                element: <ParentPageLayout element={<CustomFieldsPage />} />,
                routes: [
                    {
                        title: 'app.settings.custom_fields.edit.title',
                        path: EDIT,
                        element: <CustomFieldEditPage />
                    },
                    {
                        title: 'app.settings.custom_fields.create.title',
                        path: NEW,
                        element: <CustomFieldCreatePage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, customFieldsRoutes, {
                        header: <CustomFieldDetailsHeader />
                    })
                ]
            },
            {
                title: 'app.settings.reconciliation_rules.title',
                path: 'reconciliation-rules',
                element: <ParentPageLayout element={<ReconciliationRulesPage />} />,
                routes: getNestedRoutesWithTabLayout(UUID, reconciliationRulesRoutes, {
                    header: <ReconciliationRuleDetailsHeader />
                })
            },
            {
                title: 'app.settings.payment_retry_rules.title',
                path: 'payment-retry-rules',
                element: <ParentPageLayout element={<PaymentRetryRulesPage />} />,
                routes: getNestedRoutesWithTabLayout(UUID, paymentRetryRulesRoutes, {
                    header: <PaymentRetryRuleDetailsHeader />
                })
            },
            NotFoundRoute
        ]
    }
}
