import { APPROXIMATE_SYMBOL } from '@/constants'
import { useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { HOME_PAGE_STYLE_CONFIGURATION } from '@/pages/Home/Home.page.const'
import { Link } from '@chakra-ui/react'
import { DateFormat, Uuid } from '@webapps/numeral-ui-core'
import { formatDistanceToNow } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { chain } from 'lodash'
import { memo, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

const OVERRIDE_LOCALE_DISTANCE_DATE_ABOUT = /about |environ |alrededor |circa /gim
const AVAILABLE_DATE_LOCALES = {
    'en-GB': enGB
    /**
     * @todo
     * Add more as support grows
     */
}

interface TableCellLatestEventsCreatedAtProps {
    id: Uuid
    createdAt: DateFormat
}

export const TableCellLatestEventCreatedAt: React.FC<TableCellLatestEventsCreatedAtProps> = memo(
    ({ id, createdAt }) => {
        const intl = useIntl()
        const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
        const { paths } = useNavigationRoutes()
        const selectedLocale = useMemo(() => {
            return chain(AVAILABLE_DATE_LOCALES).get(intl.locale, enGB).value()
        }, [intl])
        const formattedDate = useMemo(() => {
            const date = new Date(createdAt)

            return formatDistanceToNow(date, {
                addSuffix: true,
                locale: selectedLocale
            }).replace(OVERRIDE_LOCALE_DISTANCE_DATE_ABOUT, APPROXIMATE_SYMBOL)
        }, [createdAt, selectedLocale])
        const onClick = useCallback(() => {
            const path = [paths.DEVELOPERS.EVENTS, id].join('/')
            navigateWithLegalEntityID(path)
        }, [paths, navigateWithLegalEntityID, id])

        return (
            <Link
                fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}
                title={formattedDate}
                aria-label={formattedDate}
                onClick={onClick}>
                {formattedDate}
            </Link>
        )
    }
)
