import { EntityAction } from '@/components'
import {
    useMutationEnableByIdUser,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindCurrentUserInfo
} from '@/hooks'
import { updateRelativeActionInPathname } from '@/providers'
import { isCurrentUser, isUserStatusDisabled, PERMISSION, User } from '@/services'
import { UseDisclosureProps } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'

export function useUserActions(item?: User, options?: UseDisclosureProps) {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const queryUserInfo = useQueryFindCurrentUserInfo()
    const mutationEnable = useMutationEnableByIdUser(uuid || item?.id)

    const hasUserStatusDisabled = isUserStatusDisabled(item)
    const hasCurrentUser = isCurrentUser(queryUserInfo.data, item)
    const hasSettingsDisableUsersPermission = hasPermission(PERMISSION.SETTINGS_DISABLE_USERS)
    const hasSettingsInviteUserPermission = hasPermission(PERMISSION.SETTINGS_INVITE_USERS)

    const actions: EntityAction[] = []

    if (hasPermission(PERMISSION.SETTINGS_EDIT_USERS)) {
        actions.push({
            onClick() {
                const { SETTINGS, EDIT, UUID } = relativePaths
                const path = uuid
                    ? updateRelativeActionInPathname(location.pathname, SETTINGS.EDIT)
                    : EDIT.replace(UUID, item?.id as string)

                navigateWithLegalEntityID(path)
            },
            children: intl.formatMessage({
                id: 'app.settings.users.edit.actions.edit.label'
            })
        })
    }

    if (hasSettingsDisableUsersPermission && !hasUserStatusDisabled && !hasCurrentUser) {
        actions.push({
            onClick: options?.onOpen,
            children: intl.formatMessage({
                id: 'app.settings.users.edit.actions.disable.label'
            }),
            color: 'red.500'
        })
    }

    if (hasSettingsInviteUserPermission && hasUserStatusDisabled) {
        actions.push({
            onClick() {
                mutationEnable.mutate()
            },
            isLoading: mutationEnable.isPending,
            children: intl.formatMessage({
                id: 'app.settings.users.edit.actions.enable.label'
            })
        })
    }

    return actions
}
