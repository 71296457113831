import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigateWithLegalEntityID } from '@/hooks'
import { ApiObjectTypeExtended } from '@/types'
import { prettyPrintCasedWords } from '@/utils/prettyPrintCasedWords' // TODO some tests fail if importing from @/utils
import { Link, LinkProps } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { PropsWithChildren, ReactNode, SyntheticEvent, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getObjectDetailPagePath } from './DetailPageLink.utils'

interface DetailPageLinkProps extends PropsWithChildren, LinkProps {
    objectId?: Uuid
    objectType: ApiObjectTypeExtended
    customLabelMessageKey?: string
}

export const DetailPageLink: React.FC<DetailPageLinkProps> = ({
    objectId,
    objectType,
    customLabelMessageKey,
    children,
    ...chakraProps
}) => {
    const intl = useIntl()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const label = useMemo<ReactNode>(() => {
        if (children) {
            return children
        }

        const defaultMessage = prettyPrintCasedWords(objectType)
        const formattedObjectType = intl.formatMessage({ id: `api.object.${objectType}`, defaultMessage }).toLowerCase()
        const labelMessageKey = customLabelMessageKey || 'app.common.link.view_object.label'
        return intl.formatMessage(
            {
                id: labelMessageKey,
                defaultMessage: objectType
            },
            {
                objectType: formattedObjectType
            }
        )
    }, [intl, objectType, customLabelMessageKey, children])

    const path = useMemo<string | undefined>(
        () => getObjectDetailPagePath(objectId, objectType),
        [objectType, objectId]
    )
    const onNavigate = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            if (path) {
                navigateWithLegalEntityID(path)
            }
        },
        [path, navigateWithLegalEntityID]
    )

    if (!path) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Link {...chakraProps} onClick={onNavigate} title={label as string}>
            {label}
        </Link>
    )
}
