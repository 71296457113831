import {
    QueryFallback,
    RestrictedSection,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { SupportEmailMailtoLink } from '@/components/@misc'
import {
    APIQueryParamTypes,
    useAPIQueryParams,
    useMutationExportExpectedPayments,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePage,
    usePermissions,
    useQueryFindAllConnectedAccounts,
    useQueryFindAllExpectedPayments,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ReconcileEntityDynamicHeader, getReconcileEntityBulkActions } from '@/pages'
import { BulkActionsProvider, BulkActionsTable, QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import {
    ConnectedAccount,
    ExpectedPayment,
    ExpectedPaymentsServiceFindAllQueryOptions,
    Transaction
} from '@webapps/numeral-ui-core'
import { get, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import {
    EXPECTED_PAYMENT_DOCUMENTATION_LINK,
    EXPECTED_PAYMENT_FILTERS,
    EXPECTED_PAYMENT_QUICK_FILTERS,
    EXPECTED_PAYMENT_TABLE_COLUMN_CUSTOMIZERS,
    EXPECTED_PAYMENT_TABLE_STATIC_STATE
} from './ExpectedPayments.page.const'
import { ExpectedPaymentsCustomColumns } from './ExpectedPayments.types'

export const ExpectedPaymentsPage: React.FC = () => {
    const page = usePage<Transaction>()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const { onAdd } = useToasts()
    const location = useLocation()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { relativePaths } = useNavigationRoutes()
    const { search, filterBy } = useAPIQueryParams<ExpectedPaymentsServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                preselected: get(location.state, APIQueryParamTypes.FilterBy),
                quickFilters: EXPECTED_PAYMENT_QUICK_FILTERS,
                filters: EXPECTED_PAYMENT_FILTERS
            }
        }
    })
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.expected_payments.title' }), [intl])

    const query = useQueryFindAllExpectedPayments({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportExpectedPayments({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const queryConnectedAccounts = useQueryFindAllConnectedAccounts()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const dataConnectedAccounts = useMemo<ConnectedAccount[]>(
        () => queryDataAggregation(queryConnectedAccounts.data),
        [queryConnectedAccounts]
    )
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const actions = useMemo<TableHeaderActionProps[]>(() => {
        const actions: TableHeaderActionProps[] = []

        if (page.hasActiveContext) {
            return []
        }

        actions.push({
            onClick() {
                mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
            },
            children: intl.formatMessage({ id: 'app.common.actions.export' }),
            isDisabled: isDataEmpty || mutationExport.isPending
        })

        if (hasPermission(PERMISSION.PAYMENTS_CREATE_EXPECTED_PAYMENTS)) {
            actions.push({
                onClick() {
                    navigateWithLegalEntityID(relativePaths.NEW, {
                        state: {
                            data: dataConnectedAccounts
                        }
                    })
                },
                children: intl.formatMessage({ id: 'app.payments.expected_payments.create.form.actions.submit.label' })
            })
        }

        return actions
    }, [
        hasPermission,
        intl,
        isDataEmpty,
        mutationExport,
        navigateWithLegalEntityID,
        relativePaths.NEW,
        dataConnectedAccounts,
        search.parsedState,
        filterBy.parsedState,
        page
    ])
    const bulkActions = useMemo(() => {
        return getReconcileEntityBulkActions(!page.hasActiveContext)
    }, [page])
    const onTableRowClick = useTableRowNavigation<ExpectedPayment>('id', relativePaths.PAYMENTS.DETAILS)

    return (
        <PageLayout className="ExpectedPayments">
            <ReconcileEntityDynamicHeader>
                <PageHeaderLayout title={title} showBackButton={false} />
            </ReconcileEntityDynamicHeader>
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.PAYMENTS_VIEW_EXPECTED_PAYMENTS}>
                <QuickFilterProvider<ExpectedPaymentsServiceFindAllQueryOptions> filterBy={filterBy}>
                    <BulkActionsProvider<ExpectedPayment, ExpectedPaymentsServiceFindAllQueryOptions>
                        targetObject={ApiObjectTypeExtendedSchema.enum.expected_payment}
                        actions={bulkActions}
                        filterBy={filterBy}
                        search={search}
                        data={data}>
                        <TableHeader<ExpectedPaymentsServiceFindAllQueryOptions>
                            objectType={ApiObjectTypeExtendedSchema.enum.expected_payment}
                            isLoading={query.isLoading}
                            actions={actions}
                            filterBy={filterBy}
                            search={search}
                        />
                        <QueryFallback
                            objectType={ApiObjectTypeExtendedSchema.enum.expected_payment}
                            isLoading={query.isLoading}
                            isSuccess={query.isSuccess}
                            isError={query.isError}
                            isDataEmpty={isDataEmpty}
                            learnMoreLinkUrl={EXPECTED_PAYMENT_DOCUMENTATION_LINK}
                            customEmptyStateDescriptionKey="app.payments.expected_payments.empty_state.description">
                            <BulkActionsTable<ExpectedPayment, ExpectedPaymentsCustomColumns>
                                data={data}
                                onRowClick={onTableRowClick}
                                onScrollToBottom={query.fetchNextPage}
                                isLoading={query.hasNextPage}
                                state={EXPECTED_PAYMENT_TABLE_STATIC_STATE}
                                columnCustomizers={EXPECTED_PAYMENT_TABLE_COLUMN_CUSTOMIZERS}
                            />
                        </QueryFallback>
                    </BulkActionsProvider>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
