import { useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { isFunction } from 'lodash'
import { useEffect } from 'react'

/**
 * @usage
 * Drop anywhere (in a page or component) to do a redirection to the root of the app '/'.
 */
export function useNavigationRouteGuard(predicateOrCondition: CallableFunction | boolean, redirectTo?: string) {
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { paths } = useNavigationRoutes()

    useEffect(() => {
        const condition = isFunction(predicateOrCondition) ? predicateOrCondition() : predicateOrCondition

        if (!condition) {
            navigateWithLegalEntityID(redirectTo || paths.NOT_FOUND, { replace: true })
        }
    }, [predicateOrCondition, navigateWithLegalEntityID, paths, redirectTo])
}
