import { Icon, IconProps } from '@chakra-ui/react'

export const NumeralLogo: React.FC<IconProps> = (props) => (
    <Icon width="36" height="36" viewBox="0 0 36 36" {...props}>
        <path
            d="M27.3438 0.5H7.65625C3.42782 0.5 0 3.92782 0 8.15625V27.8438C0 32.0722 3.42782 35.5 7.65625 35.5H27.3438C31.5722 35.5 35 32.0722 35 27.8438V8.15625C35 3.92782 31.5722 0.5 27.3438 0.5Z"
            fill="#3B61F4"
        />
        <path
            d="M27.3447 26.7495H20.7822V15.812H14.2197V26.7495H7.65723V12.5308C7.65723 10.7186 9.12629 9.24951 10.9385 9.24951H24.0635C25.8756 9.24951 27.3447 10.7186 27.3447 12.5308V26.7495Z"
            fill="white"
        />
    </Icon>
)
