import { EMPTY_STATE_IMAGES, EmptyState, EmptyStateProps } from '@/components'
import { useAuth, useNavigationRoutes } from '@/hooks'
import { PageLayout } from '@/layouts'
import { Flex } from '@chakra-ui/react'
import { SyntheticEvent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

export const LoginErrorPage: React.FC = () => {
    const [searchParams] = useSearchParams()
    const intl = useIntl()
    const { onLogout } = useAuth()
    const { paths } = useNavigationRoutes()
    const emptyContentProps = useMemo<EmptyStateProps>(() => {
        const errorType = searchParams.get('error_type')
        const defaultTitle = intl.formatMessage({ id: 'app.account.login_error.title' })
        const defaultDescription = intl.formatMessage({ id: 'app.account.login_error.description' })
        const title = intl.formatMessage({
            id: `app.account.login_error.${errorType}.title`,
            defaultMessage: defaultTitle
        })
        const description = intl.formatMessage({
            id: `app.account.login_error.${errorType}.description`,
            defaultMessage: defaultDescription
        })
        const propsButton = {
            onClick(event: SyntheticEvent) {
                event.stopPropagation()
                onLogout()
            },
            colorScheme: 'blue',
            fontWeight: 'normal',
            variant: 'link',
            textDecoration: 'underline',
            children: <FormattedMessage id="app.account.login_error.back_to_login.label" />
        }

        return {
            title,
            description,
            propsButton,
            imageSrc: EMPTY_STATE_IMAGES.SETTINGS
        }
    }, [intl, onLogout, paths, searchParams])

    return (
        <PageLayout className="LoginError">
            <Flex justifyContent="center" flexGrow="2" height="inherit">
                <EmptyState {...emptyContentProps} />
            </Flex>
        </PageLayout>
    )
}
