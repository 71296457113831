import { RestrictedSection } from '@/components'
import { useMutationCreateWebhook, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { WebhookCreateOrEditForm } from '../@components'

export const WebhookCreatePage: React.FC = () => {
    const intl = useIntl()
    const { onAdd } = useToasts()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const title = useRef(intl.formatMessage({ id: 'app.developers.webhooks.add.title' })).current
    const mutation = useMutationCreateWebhook({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const path = [paths.DEVELOPERS.WEB_HOOKS, response.id, relativePaths.DEVELOPERS.DETAILS].join('/')

            navigateWithLegalEntityID(path, {
                state: response
            })

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.developers.webhooks.create.success.toast.title" />,
                description: <FormattedMessage id="app.developers.webhooks.create.success.toast.description" />
            })
        }
    })

    return (
        <PageLayout className="WebhookCreate">
            <PageHeaderLayout title={title} />
            <RestrictedSection permission={PERMISSION.DEVELOPERS_CONFIGURE_WEBHOOKS}>
                <WebhookCreateOrEditForm onSubmit={mutation.mutate} isLoading={mutation.isPending} />
            </RestrictedSection>
        </PageLayout>
    )
}
