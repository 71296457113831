import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import {
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindByIdReconciliationRule
} from '@/hooks'
import { PERMISSION } from '@/services'
import { Nullable } from '@/types'
import { Link, Skeleton } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { MouseEvent, useCallback } from 'react'
import { RestrictedData } from '../RestrictedData'

interface ReconciliationRuleNameProps {
    reconciliationRuleId: Nullable<Uuid>
}

export const ReconciliationRuleName: React.FC<ReconciliationRuleNameProps> = React.memo(({ reconciliationRuleId }) => {
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdReconciliationRule(reconciliationRuleId, {
        enabled: globalThis.Boolean(reconciliationRuleId)
    })
    const onClick = useCallback(
        (event: MouseEvent<HTMLAnchorElement>) => {
            const path = `${paths.SETTINGS.RECONCILIATION_RULES}/${reconciliationRuleId}/${relativePaths.SETTINGS.DETAILS}`
            event.stopPropagation()
            navigateWithLegalEntityID(path)
        },
        [paths, relativePaths, navigateWithLegalEntityID, reconciliationRuleId]
    )

    switch (true) {
        case !hasPermission(PERMISSION.SETTINGS_VIEW_RECONCILIATION_RULES): {
            return <RestrictedData />
        }
        case query.isLoading: {
            return <Skeleton height="14px" width="50%" />
        }

        case !reconciliationRuleId:
        case query.isError:
        case isEmpty(query.data?.name): {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        default: {
            return (
                <Link onClick={onClick} title={query.data?.name}>
                    {query.data?.name}
                </Link>
            )
        }
    }
})
