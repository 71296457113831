import { getFormButtonLoadingLeftIcon } from '@/components'
import {
    useBulkActions,
    useMutationCreateConcurrentReconciliations,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePage,
    usePrevious,
    useToasts
} from '@/hooks'
import {
    enrichReconciliationTargetObjectsSelection,
    getReconcilableTargetObjectAbsolutePath,
    getReconcilableTargetObjectRelativeReconciliationsPath,
    RECONCILE_ENTITY_PAGE_CONTEXT,
    useReconciliation
} from '@/pages'
import { TOAST_VARIANTS, useBulkActionsPluralization } from '@/providers'
import { computeReconcilableAmount, isReconcilablePaymentObjectType, RECONCILIATIONS_REJECTIONS } from '@/services'
import { Payment } from '@/types'
import { Button, ButtonProps } from '@chakra-ui/react'
import {
    ApiObjectType,
    ApiObjectTypeSchema,
    ReconciliationCreateOrUpdateData,
    Transaction
} from '@webapps/numeral-ui-core'
import { useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { isReconciliationDisabled } from './ReconcileEntityFooter.utils'

export const ReconcileEntityFooterAction: React.FC<{ targetObject: ApiObjectType }> = ({ targetObject }) => {
    const { getNonPersistedState } = usePage<Payment | Transaction>()
    const reconcileEntityNonPersistedState = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
    const previousReconcileEntityNonPersistedState = usePrevious(reconcileEntityNonPersistedState)
    const { relativePaths, paths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const { onStartReconciliation, onCancelReconciliation } = useReconciliation()
    const { selection, data, isLoading, onResetSelection } = useBulkActions<typeof reconcileEntityNonPersistedState>()
    const sourceObjectPluralization = useBulkActionsPluralization(reconcileEntityNonPersistedState?.object, 1, false)
    const targetObjectPluralization = useBulkActionsPluralization(targetObject, selection?.size, false)
    const location = useLocation()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const mutation = useMutationCreateConcurrentReconciliations({
        onSuccess(response) {
            onResetSelection()
            onCancelReconciliation()

            switch (response) {
                case RECONCILIATIONS_REJECTIONS.PARTIAL_REJECTION: {
                    const { formattedObjectType } = targetObjectPluralization

                    onAdd({
                        status: 'warning',
                        variant: TOAST_VARIANTS.WARNING,
                        title: <FormattedMessage id="app.reconcile.create.warning.toast.title" />,
                        description: (
                            <FormattedMessage
                                id="app.reconcile.create.warning.toast.description"
                                values={{
                                    objectType: formattedObjectType
                                }}
                            />
                        )
                    })
                    break
                }

                default: {
                    const { formattedObjectType } = sourceObjectPluralization

                    onAdd({
                        status: 'success',
                        variant: TOAST_VARIANTS.SUCCESS,
                        title: <FormattedMessage id="app.reconcile.create.success.toast.title" />,
                        description: (
                            <FormattedMessage
                                id="app.reconcile.create.success.toast.description"
                                values={{
                                    objectType: formattedObjectType
                                }}
                            />
                        )
                    })
                }
            }
        },
        onError() {
            const { formattedObjectType } = targetObjectPluralization

            onAdd({
                status: 'error',
                variant: TOAST_VARIANTS.ERROR,
                title: <FormattedMessage id="app.reconcile.create.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.reconcile.create.error.toast.description"
                        values={{
                            objectType: formattedObjectType
                        }}
                    />
                )
            })
        }
    })
    const enrichedSelection = useMemo(() => {
        return enrichReconciliationTargetObjectsSelection<typeof reconcileEntityNonPersistedState>(selection, data)
    }, [selection, data])

    const onReconcile = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()

            if (!reconcileEntityNonPersistedState) {
                return
            }

            const { id } = reconcileEntityNonPersistedState
            const metadata = Object.create(null)
            const data: ReconciliationCreateOrUpdateData[] = enrichedSelection.map((item) => {
                const amount = computeReconcilableAmount(reconcileEntityNonPersistedState, item)

                if (isReconcilablePaymentObjectType(reconcileEntityNonPersistedState)) {
                    return {
                        transaction_id: item?.id,
                        payment_id: id,
                        amount,
                        metadata
                    }
                }

                return {
                    transaction_id: id,
                    payment_id: item?.id,
                    amount,
                    metadata
                }
            })

            mutation.mutate(data)
        },
        [enrichedSelection, reconcileEntityNonPersistedState, mutation]
    )
    const onNavigate = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            const selectedItem = enrichedSelection.at(0)
            event.stopPropagation()

            switch (targetObject) {
                case ApiObjectTypeSchema.enum.transaction: {
                    const { ACCOUNTS } = relativePaths
                    const path = [location.pathname, selectedItem?.id, ACCOUNTS.RECONCILE].join('/')

                    navigateWithLegalEntityID(path)

                    break
                }

                default: {
                    onStartReconciliation(ApiObjectTypeSchema.enum.transaction, selectedItem)
                }
            }
        },
        [targetObject, navigateWithLegalEntityID, relativePaths, enrichedSelection]
    )

    const propsButton: ButtonProps = useMemo(() => {
        const isReconcileEntityLoading = mutation.isPending || isLoading
        const onClick = reconcileEntityNonPersistedState ? onReconcile : onNavigate
        const isDisabled = reconcileEntityNonPersistedState
            ? isReconciliationDisabled<(typeof enrichedSelection)[0]>(
                  reconcileEntityNonPersistedState,
                  enrichedSelection
              )
            : isReconcileEntityLoading

        return {
            onClick,
            isDisabled: isDisabled || isReconcileEntityLoading,
            leftIcon: getFormButtonLoadingLeftIcon(isReconcileEntityLoading)
        }
    }, [reconcileEntityNonPersistedState, enrichedSelection, isLoading, mutation])

    useEffect(() => {
        if (mutation.isSuccess && previousReconcileEntityNonPersistedState) {
            const { id, object } = previousReconcileEntityNonPersistedState
            const contextObjectAbsolutePath = getReconcilableTargetObjectAbsolutePath(object)
            const contextObjectRelativePath = getReconcilableTargetObjectRelativeReconciliationsPath(object)
            const path = [contextObjectAbsolutePath, id, contextObjectRelativePath].join('/')

            navigateWithLegalEntityID(path)
        }
    }, [mutation, previousReconcileEntityNonPersistedState])

    return (
        <Button {...propsButton}>
            <FormattedMessage id="app.reconcile.footer.actions.reconcile.label" />
        </Button>
    )
}
