import { RestrictedSection } from '@/components'
import { useMutationUploadFile, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { ENTITY_FEATURE, PERMISSION, UPLOADER_HTTP_ERROR_CODE } from '@/services'
import { Box } from '@chakra-ui/react'
import { get } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PaymentOrderUploadForm } from './@components'

export const PaymentOrderUploadPage: React.FC = () => {
    const intl = useIntl()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { paths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.payment_orders.upload.title' }), [intl])
    const mutation = useMutationUploadFile({
        onSuccess(response) {
            const { PAYMENTS } = paths

            navigateWithLegalEntityID(PAYMENTS.PAYMENT_ORDERS)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.payments.payment_orders.upload.form.validation.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.payments.payment_orders.upload.form.validation.success.toast.description" />
                )
            })
        },
        onError(error) {
            let errorToastDescription
            const message = get(error, 'response.data.message', error.message)

            switch (error.response?.status) {
                case UPLOADER_HTTP_ERROR_CODE.DUPLICATE:
                    errorToastDescription = (
                        <FormattedMessage id="app.payments.payment_orders.upload.form.validation.error.duplicate.toast.description" />
                    )
                    break
                default:
                    errorToastDescription = (
                        <FormattedMessage
                            id="app.payments.payment_orders.upload.form.validation.error.default.toast.description"
                            values={{ message }}
                        />
                    )
            }

            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.payments.payment_orders.upload.form.validation.error.toast.title" />,
                description: errorToastDescription
            })
        }
    })

    return (
        <PageLayout className="PaymentOrderUpload">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_CREATE_PAYMENT_ORDERS}>
                <Box marginTop="8px">
                    <PaymentOrderUploadForm
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={mutation.isPending}
                    />
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
