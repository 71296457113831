import { FilesIcon, HomeIcon } from '@/components/@icons'
import { ENVIRONMENT_VARIABLES } from '@/configuration'
import { MainLayout, NestedTabsLayout } from '@/layouts'
import { DebugPage, HomePage, MissingAccessPage } from '@/pages'
import { chain, get } from 'lodash'
import { Navigate } from 'react-router-dom'
import { withUserAccess } from '../AuthProvider'
import {
    getAccountNavigationRoutes,
    getAccountsNavigationRoutes,
    getCounterpartiesNavigationRoutes,
    getDevelopersNavigationRoutes,
    getPaymentsNavigationRoutes,
    getSettingsNavigationRoutes
} from './@utils'
import { getFilesNavigationRoutes } from './@utils/getFilesNavigationRoutes'
import {
    CallbackRoute,
    ExtraLogoutRoute,
    InitialCallbackRoute,
    NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS,
    NotFoundRoute,
    PATHNAME_OBJECT_UUID_AND_RELATIVE_ACTION_REGEX
} from './NavigationRoutesProvider.const'
import { NavigationRoute } from './NavigationRoutesProvider.types'

export function getNestedRoutesWithTabLayout(path: string, routes: NavigationRoute[], props?: any) {
    const generateRoutes = (path: string, routes: NavigationRoute[], element: JSX.Element) => {
        const getIndexRoutePath = get(routes, '[0].path', '/')
        const indexRouteWithRedirect = { index: true, element: <Navigate replace={true} to={getIndexRoutePath} /> }
        return [
            {
                path,
                element,
                routes: [indexRouteWithRedirect, ...routes, NotFoundRoute]
            }
        ]
    }
    return generateRoutes(path, routes, <NestedTabsLayout routes={routes} {...props} />)
}

export function getNavigationRoutes(): NavigationRoute[] {
    const MainLayoutWithUserAccess = withUserAccess(MainLayout)

    return [
        getAccountNavigationRoutes(),
        {
            title: 'app.missing_access.title',
            path: `${NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS.LEGAL_ENTITY_ID}/missing_access`,
            element: <MissingAccessPage />
        },
        {
            path: NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS.LEGAL_ENTITY_ID,
            element: <MainLayoutWithUserAccess />,
            routes: [
                { index: true, element: <Navigate replace={true} to="home" /> },

                {
                    title: 'app.home.title',
                    path: 'home',
                    element: <HomePage />,
                    icon: <HomeIcon />
                },
                getPaymentsNavigationRoutes(),
                getAccountsNavigationRoutes(),
                getCounterpartiesNavigationRoutes(),
                getFilesNavigationRoutes(),
                getDevelopersNavigationRoutes(),
                getSettingsNavigationRoutes(),
                {
                    title: 'app.debug.title',
                    path: 'debug',
                    element: <DebugPage />,
                    icon: <FilesIcon />,
                    configuration: {
                        isHidden: ENVIRONMENT_VARIABLES.PROD,
                        isFooter: true
                    }
                },
                NotFoundRoute
            ]
        },
        InitialCallbackRoute,
        CallbackRoute,
        ExtraLogoutRoute,
        NotFoundRoute
    ]
}

export function getPrivateTopLevelRoutes(routes: NavigationRoute[]): NavigationRoute[] {
    const privateTopLevelRoutesFinder = ({ path }: NavigationRoute): boolean =>
        path === NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS.LEGAL_ENTITY_ID
    const topLevelRoute: NavigationRoute | undefined = routes?.find(privateTopLevelRoutesFinder)

    function flatFilter(nestedProp: string, compareKey: string, compareValue: any, source?: any[]) {
        return source?.filter((sourceItem) => {
            const shouldKeep: boolean = sourceItem[compareKey] !== compareValue
            if (shouldKeep && sourceItem[nestedProp]) {
                sourceItem[nestedProp] = flatFilter(nestedProp, compareKey, compareValue, sourceItem[nestedProp])
            }
            return shouldKeep
        })
    }

    return flatFilter('routes', 'path', '*', topLevelRoute?.routes) || []
}

/**
 * @description
 * Some relative paths (/invite, /new, /edit) have to completely overwrite the parent outlet.
 */
export function shouldRenderOutlet(pathname: string, uuid = ''): boolean {
    const patternOROperator = '|'
    const { UUID } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    const pattern = chain(NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS)
        .pick('UPLOAD', 'INVITE', 'NEW', 'EDIT', 'IMPORT', 'CANCEL', 'DENY', 'REJECT', 'RETURN', 'RECONCILE')
        .invokeMap('replace', UUID, uuid)
        .join(patternOROperator)
        .value()

    const outletRegExp = new RegExp(`(${pattern})$`, 'i')

    return !!(outletRegExp.test(pathname) || uuid)
}

/**
 *  @description
 * Match the legal entity ID in a pathname starting with "c/:legalEntityID/..."
 * It matches the ID even when it is not a valid UUID
 */
export function getLegalEntityIDFromPathname(pathname: string): string | undefined {
    return pathname.match(/^\/c\/([^/]+)/)?.[1] || undefined
}

/**
 * @description
 * Remove the part of the pathname matching {uuid}/{action}$, while making sure we never match with the legal entity ID path param
 */
export function removeObjectUUIDAndRelativeActionInPathname(pathname: string): string {
    return pathname?.replace(PATHNAME_OBJECT_UUID_AND_RELATIVE_ACTION_REGEX, '')
}

/**
 * @description
 * Update the pathname matching {uuid}/{action}$ with {uuid}/{new_action}$
 */
export function updateRelativeActionInPathname(pathname: string, replacement: string): string {
    return pathname?.replace(PATHNAME_OBJECT_UUID_AND_RELATIVE_ACTION_REGEX, `/$1/${replacement}`)
}

export function addOrReplaceLegalEntityIDInPathname(pathname: string, newLegalEntityID?: string): string {
    const legalEntityIdPathRegex = /^\/c\/[0-9a-fA-F-]{36}/

    if (!newLegalEntityID) {
        return pathname
    }

    if (legalEntityIdPathRegex.test(pathname)) {
        // If path starts with "c/<UUID>", replace the UUID
        return pathname.replace(/^\/c\/[0-9a-fA-F-]{36}/, `/c/${newLegalEntityID}`)
    } else {
        // If path does not start with "c/<UUID>", prepend "c/<UUID>"
        return `/c/${newLegalEntityID}${pathname}`
    }
}

export function navigationProviderIndexRoutesFilter({ index }: NavigationRoute): boolean {
    return !index
}

export function navigationProviderGroupRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !route.configuration?.isGroup : false
}

export function navigationProviderHiddenRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !route.configuration?.isHidden : false
}

export function navigationProviderFooterRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !route.configuration?.isFooter : false
}

export function navigationProviderDisabledRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !route.configuration?.isDisabled : false
}

export function navigationProviderOutletExceptionRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !shouldRenderOutlet(route.path) : false
}
