import { Box, Fade } from '@chakra-ui/react'
import { memo } from 'react'
import { NumeralLogo } from '../@icons'
import './SplashScreen.scss'

export const SplashScreen: React.FC = memo(() => {
    return (
        <Box className="splashScreen">
            <Fade in={true} transition={{ enter: { duration: 1 } }}>
                <NumeralLogo animation="fade-in" />
            </Fade>
        </Box>
    )
})
