import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useNavigateWithLegalEntityID, useNavigationRoutes, usePermissions } from '@/hooks'
import { useQueryFindAllCustomFields } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, CustomField } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { CUSTOM_FIELDS_TABLE_COLUMN_CUSTOMIZERS, CUSTOM_FIELDS_TABLE_STATIC_STATE } from './CustomFields.page.const'

export const CustomFieldsPage: React.FC = () => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindAllCustomFields()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<CustomField>('id')
    const actions = useMemo(() => {
        if (!hasPermission(PERMISSION.SETTINGS_CREATE_CUSTOM_FIELDS)) {
            return
        }

        return [
            {
                onClick() {
                    navigateWithLegalEntityID(relativePaths.NEW)
                },
                children: intl.formatMessage({ id: 'app.settings.custom_fields.add' })
            }
        ]
    }, [navigateWithLegalEntityID, relativePaths, intl, hasPermission])

    return (
        <PageLayout className="CustomFields">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_CUSTOM_FIELDS}>
                <TableHeader
                    objectType={ApiObjectTypeExtendedSchema.enum.custom_field}
                    isLoading={query.isLoading}
                    actions={actions}
                />
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.custom_field}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}>
                    <Table<CustomField>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        columnCustomizers={CUSTOM_FIELDS_TABLE_COLUMN_CUSTOMIZERS}
                        state={CUSTOM_FIELDS_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
