import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationUpdateByIdPaymentOrder,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdPaymentOrder
} from '@/hooks'
import { PageLayout } from '@/layouts'
import { PaymentOrderHeader } from '@/pages'
import { updateRelativeActionInPathname } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import { PaymentOrderEditForm } from './@components'

export const PaymentOrderEditPage: React.FC = () => {
    const { uuid } = useParams()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdPaymentOrder(uuid)
    const mutation = useMutationUpdateByIdPaymentOrder(uuid, {
        onSuccess() {
            const { PAYMENTS } = relativePaths
            const path = updateRelativeActionInPathname(location.pathname, PAYMENTS.DETAILS)

            navigateWithLegalEntityID(path)
        }
    })

    return (
        <PageLayout className="PaymentOrderEdit">
            <PaymentOrderHeader />
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_EDIT_PAYMENT_ORDERS}>
                <Box marginTop="8px">
                    <QueryFallback
                        objectType={ApiObjectTypeExtendedSchema.enum.payment_order}
                        isLoading={query.isLoading}
                        isSuccess={query.isSuccess}
                        isError={query.isError || isEmpty(query?.data)}
                        isDataEmpty={isEmpty(query?.data)}>
                        <PaymentOrderEditForm
                            onSubmit={mutation.mutate}
                            disabled={mutation.isPending}
                            loading={mutation.isPending}
                            data={query.data}
                        />
                    </QueryFallback>
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
