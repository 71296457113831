import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useNavigateWithLegalEntityID, useNavigationRoutes, usePermissions, useQueryFindAllWebhooks } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION, Webhook } from '@/services'
import { queryDataAggregation } from '@/utils'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    WEBHOOKS_DOCUMENTATION_LINK,
    WEBHOOKS_TABLE_COLUMN_CUSTOMIZERS,
    WEBHOOKS_TABLE_STATIC_STATE
} from './Webhooks.page.const'

export const WebhooksPage: React.FC = () => {
    const intl = useIntl()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { hasPermission } = usePermissions()
    const { relativePaths } = useNavigationRoutes()
    const onNavigateToNew = useCallback(() => {
        navigateWithLegalEntityID(relativePaths.NEW)
    }, [navigateWithLegalEntityID, relativePaths])
    const query = useQueryFindAllWebhooks()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<Webhook>('id')
    const actionsProps = hasPermission(PERMISSION.DEVELOPERS_CONFIGURE_WEBHOOKS)
        ? [
              {
                  onClick: onNavigateToNew,
                  children: intl.formatMessage({
                      id: 'app.developers.webhooks.add'
                  })
              }
          ]
        : undefined

    return (
        <PageLayout className="Webhooks">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_WEBHOOKS}>
                <TableHeader<Webhook> objectType={ApiObjectTypeExtendedSchema.enum.webhook} actions={actionsProps} />
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.webhook}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    learnMoreLinkUrl={WEBHOOKS_DOCUMENTATION_LINK}
                    customEmptyStateDescriptionKey="app.developers.webhooks.empty_state.description">
                    <Table<Webhook>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={WEBHOOKS_TABLE_STATIC_STATE}
                        columnCustomizers={WEBHOOKS_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
