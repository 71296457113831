import { useNavigateWithLegalEntityID } from '@/hooks'
import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormPageFooterProps } from './FormPageFooter.types'
import { FormPageFooterAlertDialog } from './FormPageFooterAlertDialog'

export const FormPageFooter: React.FC<FormPageFooterProps> = ({
    onCancel,
    onSubmit,
    isDisabled,
    isLoading,
    submitLabelMessageId = 'app.common.form.actions.submit.label',
    showUnsavedChangesWarning = false,
    ...flexProps
}) => {
    const formikContext = useFormikContext()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const onFormCancel = useMemo(() => {
        const goBackOneStep = () => {
            navigateWithLegalEntityID(-1)
        }
        return onCancel || goBackOneStep
    }, [onCancel, navigateWithLegalEntityID])
    const { isOpen, onClose, onOpen } = useDisclosure()
    const onCancelWithAlertDialog = useCallback(() => {
        if (showUnsavedChangesWarning && formikContext?.dirty) {
            onOpen()
            return
        }

        onFormCancel()
    }, [onFormCancel, formikContext, onOpen, showUnsavedChangesWarning])

    return (
        <Flex gap="16px" marginTop="32px" {...flexProps}>
            <Button
                onClick={onCancelWithAlertDialog}
                padding="16px!important"
                variant="secondary"
                isDisabled={isLoading}>
                <FormattedMessage id="app.common.form.actions.cancel.label" />
            </Button>
            <Button
                onClick={onSubmit}
                type="submit"
                padding="16px!important"
                isDisabled={isDisabled}
                isLoading={isLoading}>
                <FormattedMessage id={submitLabelMessageId} />
            </Button>
            <FormPageFooterAlertDialog isOpen={isOpen} onClose={onClose} onConfirm={onFormCancel} />
        </Flex>
    )
}
