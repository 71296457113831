import { RestrictedSection } from '@/components'
import { LOCATION_STATE_KEYS } from '@/constants'
import { useMutationCreateAPIKey, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { APIKeyForm } from '../@components'

export const APIKeyCreatePage: React.FC = () => {
    const intl = useIntl()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { onAdd } = useToasts()
    const { paths, relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.developers.api_keys.add.title' }), [intl])
    const mutation = useMutationCreateAPIKey({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const path = [paths.DEVELOPERS.API_KEYS, response.id, relativePaths.DEVELOPERS.DETAILS].join('/')

            navigateWithLegalEntityID(path, {
                state: { [LOCATION_STATE_KEYS.API_KEY]: response.api_key_plain }
            })

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.developers.api_keys.create.success.toast.title" />,
                description: <FormattedMessage id="app.developers.api_keys.create.success.toast.description" />
            })
        }
    })

    return (
        <PageLayout className="APIKeyCreate">
            <PageHeaderLayout title={title} />
            <RestrictedSection permission={PERMISSION.DEVELOPERS_CONFIGURE_API_KEYS}>
                <APIKeyForm onSubmit={mutation.mutate} disabled={mutation.isPending} loading={mutation.isPending} />
            </RestrictedSection>
        </PageLayout>
    )
}
