import { RestrictedSection } from '@/components'
import { useMutationCreatePaymentOrder, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers/ToastsProvider'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PaymentOrderCreateErrorNotification, PaymentOrderForm } from './@components'
import { PAYMENT_ORDER_CREATE_ERROR_TIMEOUT } from './PaymentOrderCreate.page.const'

export const PaymentOrderCreatePage: React.FC = () => {
    const intl = useIntl()
    const navigateWithLegalEntityID = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.payment_orders.create.title' }), [intl])
    const mutation = useMutationCreatePaymentOrder({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { PAYMENTS } = paths
            const path = [PAYMENTS.PAYMENT_ORDERS, response.id, relativePaths.PAYMENTS.DETAILS].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: (
                    <FormattedMessage id="app.payments.payment_orders.create.form.validation.create.success.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.payments.payment_orders.create.form.validation.create.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                duration: PAYMENT_ORDER_CREATE_ERROR_TIMEOUT,
                title: (
                    <FormattedMessage id="app.payments.payment_orders.create.form.validation.create.error.toast.title" />
                ),
                description: <PaymentOrderCreateErrorNotification error={error} />
            })
        }
    })

    return (
        <PageLayout className="PaymentOrderCreate">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_CREATE_PAYMENT_ORDERS}>
                <Box marginTop="8px">
                    <PaymentOrderForm
                        onSubmit={mutation.mutate}
                        disabled={mutation.isPending}
                        loading={mutation.isPending}
                    />
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
